import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Section from "../Section/Section";
import NCard from "../Card/NCard";
import "../News/style.css";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import Imghiring from "../../assets/img/TRABAJA-con-nosotros.svg";

import { getJobOffers } from "../../redux/actions/JobsActions";

export const WorkWithUs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobOffers());
  }, []);
  const { JobsLists } = useSelector((state) => state.jobsOffers);
  return (
    <div className='Work-container'>
      <h1 className='titulo-ecosistema'>Trabaja Con Nosotros</h1> 
      
      {/*
      <div className={"job_list"}>
        {JobsLists?.map((element) => (
          <NCard
            key={element._id}
            img={
              element.picture &&
              element.picture.url &&
              element.picture.url.small
            }
            alt="imagen de ejemplo de productos"
            className="img-card-work"
            texto={element.description}
            title={element.name}
            id={element._id}
          />
        ))}
      </div> */}

        <div className="width-img">
          <img
            src={Imghiring}
            alt="Planta ecoideas y equipo"
            className="img-fluid"
          />
        </div>

  
          <div className="container-text-work">
            
           
                <h2>¿Buscas un nuevo camino profesional?</h2>   
                  <p className="container-text-work"> En Biotú, valoramos tu potencial. <br/>
                                ¿Te gustaría formar parte de nuestro equipo?<br/>
            
            Envía tu hoja de vida a <strong> servicioalcliente@biotu.com.co </strong> y descubre las oportunidades que tenemos para ti.
            </p>

          </div>
 
    </div>
  );
};
export default WorkWithUs;
