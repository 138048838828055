import React from 'react';
import { Carousel } from 'react-bootstrap';
import './style.css';

import ACarousel_image2 from '../../assets/img/ACarrousel_img2.png';

const About_Carousel = () => {
  const slides = [
    
  
    {
      image:  ACarousel_image2,
     /* title: 'Planta sola2',*/
    }
  ];

  return (
    <div className="carousel-container">
    <Carousel>
      {slides.map((slide, index) => (
        <Carousel.Item key={index} interval={2000}>
          <img
            className="mx-auto d-block carousel-img-size"
            src={slide.image}
            alt={`Slide ${index + 1}`}
           
          />
          <Carousel.Caption>
            <h3>{slide.title}</h3>
            <p>{slide.subtitle}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
    </div>
  );
};

export default About_Carousel;